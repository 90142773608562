import React from "react";

const Intro = ({ isBg }) => {
  return (
    //  <!-- ========== Hero section start ========== -->
    <section
      id="hero"
      className={`hero hero__padding overflow-hidden position-relative ${
        isBg === "yes" ? "bg-one" : ""
      }`}
    >
<img
                    className="img-background"
                    src="assets/images/background.jpg"
                    alt="Image de fond d'une colonne vertébrale"/>
      <div className="circle x1"></div>
      <div className="circle x2"></div>
      <div className="circle x3"></div>
      <div className="circle x4"></div>
      <div className="circle x5"></div>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 mb-4 mb-lg-0">
            <div className="hero__content position-relative">
              <h1 className="display-4 mb-2 text-capitalize">Léa Baubin</h1>
              <div className="badge-text fs-5 mb-4">
                Ostéopathe D.O.
              </div>
              <p className="mb-3 fs-5">
                Formée à la prise en charge des femmes enceintes et nourrissons à Villeneuve-Loubet.
              </p>
                <p className="mb-5 fs-6"><i>"J'accompagne les femmes dans toutes les étapes de leur vie"</i></p>
              <a target={"_blank"} rel={"noreferrer"} href="https://www.doctolib.fr/osteopathe/villeneuve-loubet/lea-baubin/booking" className="button button__primary me-3">
                <span>>> Prendre rendez-vous </span>
              </a>
              {/*<a*/}
              {/*  href="https://youtu.be/qg0_FinB6EE"*/}
              {/*  className="glightbox3 btn__secondary"*/}
              {/*>*/}
              {/*  <i className="icofont-play-alt-2"></i> About me*/}
              {/*</a>*/}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="hero__author text-center">
              <div className="hero__author--inner3">
                <div className="hero__author--inner3--wrapper">
                  <img
                    width="500"
                    className="img-fluid"
                    src="assets/images/profile.jpg"
                    alt="Léa Baubin, Ostéopathe D.O. à Villenneuve Loubet"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    // <!-- ========== Hero section end ========== -->
  );
};

export default Intro;
