import {default as React, useState} from "react";
import { Navbar } from "react-bootstrap";
import {Link} from "react-router-dom";

import { siteLogo } from "../../global";
import headerData from "../../data/header.json";


const Header = () => {
  const [fix, setFix] = useState(false);
  function setFixed() {
    if (window.scrollY >= 100) {
      setFix(true);
    } else {
      setFix(false);
    }
  }
  window.addEventListener("scroll", setFixed);
  const { header } = headerData;
  return (
    <header className={fix ? "header navbar_fixed" : "header"}>
      <div className="container">
        <div className="row">
          <Navbar bg="none" expand="lg">
            <a className="navbar-brand" href="/">
              {/* <!-- <h1 className="m-0">WONTED</h1> --> */}
              <img src={siteLogo.logo} alt={siteLogo.alt} loading="lazy" />
            </a>
            <Navbar.Toggle aria-controls="navbarSupportedContent">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </Navbar.Toggle>
            <Navbar.Collapse id="navbarSupportedContent">
              <ul className="navbar-nav menu ms-lg-auto">
                {header.menu?.map((data, i) =>
                    <li className="nav-item" key={i}>
                      <Link
                          id={`nav_${i}`}
                        className="benefits nav-link"
                        to={`/#${data.link}`}
                        state={{ "id": `nav_${i}` }}
                      >
                        {data.title}
                      </Link>
                    </li>
                )}
              </ul>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </div>
    </header>
  );
};

export default Header;
